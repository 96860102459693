<template>
  <div class="item-container">
    <BsCheckbox
      v-model="selectedStatus" inline
      tristate
      class="checkbox"
    />
    <p v-if="content.type !== 'plan'" class="item">
      {{ content.name }}
    </p>
    <div v-else class="item">
      <LaneItemTree :content="content" />
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import LaneItemTree from './LaneItemTree'

import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  name: 'TreeNode',
  components: {
    LaneItemTree,
    BsCheckbox
  },
  props: [
    'content',
    'children'
  ],
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      selectedNodes: state => state.data.selectedNodes,
      expandedNodes: state => state.data.expandedNodes,
      selectedLanePlans: state => state.data.selectedLanePlans
    }),
    selectedStatus: {
      get () {
        return this.children.length ? this.currentStatus : this.selected
      },
      set (value) {
        this.toggleExpandedStatus(this.content.id, value)
        if (this.children.length) {
          this.toggleSelectedStatus(this.content.id, value)
          this.children.forEach(child => {
            this.toggleExpandedStatus(child.id, value)
            this.toggleSelectedStatus(child, value)
            this.selectLanePlan(value, child.id, child.content.type)
          })
        } else {
          this.$store.commit('fieldRecordSystem/lanePlanning/toggleSelected', this.content.id)
          this.selectLanePlan(value, this.content.id, this.content.type)
        }
      }
    },
    selected: {
      get () {
        return this.selectedNodes.includes(this.content.id)
      },
      set () {
        this.$store.commit('fieldRecordSystem/lanePlanning/toggleSelected', this.content.id)
      }
    },
    childrenSelectedStatus () {
      return this.children.map(child => child.selected)
    },
    currentStatus () {
      const statusTrue = this.childrenSelectedStatus.filter(status => status)
      return statusTrue.length <= 0 ? false : statusTrue.length < this.childrenSelectedStatus.length ? null : true
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/lanePlanning', [
      'setLanePlanId'
    ]),
    selectLanePlan (value, id, type) {
      if (type === 'plan' && value && !this.selectedLanePlans.includes(id)) {
        this.setLanePlanId(id)
      } else if (type === 'plan' && !value && this.selectedLanePlans.includes(id)) {
        this.setLanePlanId(id)
      }
    },
    toggleSelectedStatus (node, value) {
      if (value && !this.selectedNodes.includes(node.id)) {
        this.$store.commit('fieldRecordSystem/lanePlanning/toggleSelected', node.id)
      } else if (!value && this.selectedNodes.includes(node.id)) {
        this.$store.commit('fieldRecordSystem/lanePlanning/toggleSelected', node.id)
      }
    },
    toggleExpandedStatus (id, value) {
      if (value && !this.expandedNodes.includes(id)) {
        this.$store.commit('fieldRecordSystem/lanePlanning/toggleExpanded', id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  width: 100%;
}
.item {
  display: flex;
  flex-direction: row;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.checkbox {
  margin: 0;
  top: -1px;
  right: -2px;
}
</style>
