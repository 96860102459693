<template lang="html">
  <div class="lane-offset-widget">
    <label>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Offset</IxRes>
    </label>
    <HelpBox>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.OffsetInfo</IxRes>
    </HelpBox>

    <div class="form-changes">
      <QuantityInput
        v-model="changeInLength" :unit-options="['m', 'cm', 'mm']"
      >
        <template #label>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.LabelOffset</IxRes>:
        </template>
      </QuantityInput>
      <IxButton @click="$emit('moveLine', changeAmount)">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.useLane</IxRes>
      </IxButton>
      <IxButton v-if="newLine" class="button" @click="$emit('newLine', changeAmount)">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.newLane</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'
import QuantityInput from '@components/forms/QuantityInput'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox,
    IxButton,
    QuantityInput
  },
  props: {
    value: Number,
    newLine: Boolean
  },
  data () {
    return {
      changeAmount: 0,
      changeInLength: {
        amount: 0,
        unit: 'm'
      }
    }
  },
  watch: {
    changeInLength () {
      if (this.changeInLength.unit === 'm') {
        this.changeAmount = this.changeInLength.amount
      } else if (this.changeInLength.unit === 'cm') {
        this.changeAmount = this.changeInLength.amount / 100
      } else if (this.changeInLength.unit === 'mm') {
        this.changeAmount = this.changeInLength.amount / 1000
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-changes {
  border: 2px solid #cbcbcb;
  padding: 8px;

  ::v-deep .input-field {
    width: 65%
  }
  .button{
    margin-left: 6px;
  }
}
</style>
