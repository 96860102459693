<template lang="html">
  <WizardNavigationFrame hide-back hide-cancel>
    <template #title>
      <span @click="hideCloseOptions = !hideCloseOptions">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SavedPlans</IxRes>
      </span>
    </template>

    <template>
      <div v-if="!hideCloseOptions" :title="$i18n.translate('geotrax.details.infos.hideInfo')" @click="hideCloseOptions = !hideCloseOptions">
        <HelpBox class="help-box">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.LanePlanExportInfoText</IxRes>
        </HelpBox>
        <hr>
      </div>

      <div>
        <IxTree
          class="tree"
          :node="tree"
          :render-component="TreeNode"
          @toggle="toggleExpanded"
        />
      </div>
      <DetailViewVectorLayer />
      <FrsLoadingIndicator :loading="loading" />

      <BsModal v-model="showModal">
        <template #title>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.DeleteLanePlansTitel</IxRes>
        </template>
        <div class="alert alert-info">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.CancelDeleteLanePlans</IxRes>
        </div>
        <button slot="footer" class="btn btn-success" @click="confirmModal">
          <IxRes>Common.SR_Common.Confirm</IxRes>
        </button>
        <button slot="footer" class="btn btn-danger" @click="hideModal">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </button>
      </BsModal>
    </template>

    <template #nextButton>
      <div class="footer-container">
        <strong>
          {{ numberOfSelectedPlans }}
          <IxRes>Areas.LanePlanning.SR_LanePlanning.SelectedLanePlansSeperator</IxRes>
          {{ totalNumberOfPlans }}
          <IxRes>Areas.LanePlanning.SR_LanePlanning.Selected</IxRes>
        </strong>

        <div class="button-container">
          <IxButton
            class="delete-button"
            large remove
            :disabled="!isValid"
            @click="openDeleteModal"
          >
            <IxRes>Areas.LanePlanning.SR_LanePlanning.DeleteLanePlan</IxRes>
          </IxButton>
          <IxButton
            large icon="download"
            :disabled="!isValid"
            @click="goToExport"
          >
            <IxRes>Areas.LanePlanning.SR_LanePlanning.ExportPlan</IxRes>
          </IxButton>
        </div>
      </div>
    </template>
  </WizardNavigationFrame>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {mapUiFlags} from 'src/vue/areas/field-record-system/helpers/ui'

import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'
import IxTree from '@components/nav/IxTree'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import WizardNavigationFrame from '@frs/components/lane-planning/planning-wizard/widgets/WizardNavigationFrame'

import TreeNode from './widgets/TreeNode'
import DetailViewVectorLayer from './widgets/DetailViewVectorLayer'

import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'
import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  components: {
    DetailViewVectorLayer,
    HelpBox,
    WizardNavigationFrame,
    IxButton,
    BsModal,
    FrsLoadingIndicator,
    IxTree
  },
  mixins: [
    DisableDefaultMapLayersMixin,
    DisableDefaultMapInteractionsMixin
  ],
  data () {
    return {
      showModal: false,
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      selectedLanePlans: state => state.data.selectedLanePlans,
      totalNumberOfPlans: state => state.data.totalNumberOfPlans
    }),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'tree'
    ]),
    ...mapUiFlags([
      'lanePlanning.exportLanePlansInfo.hideCloseOptions'
    ]),
    numberOfSelectedPlans () {
      return this.selectedLanePlans !== undefined && this.selectedLanePlans ? this.selectedLanePlans.length : 0
    },
    isValid () {
      return this.numberOfSelectedPlans > 0
    },
    TreeNode () {
      return TreeNode
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'resetLanePlansSelection',
      'deleteLanePlans'
    ]),
    openDeleteModal () {
      this.showModal = !this.showModal
    },
    hideModal () {
      this.showModal = false
    },
    confirmModal () {
      this.hideModal()
      this.$nextTick(() => {
        this.loading = true
        this.deleteLanePlans()
          .then(this.loading = false)
          .then(() => {
            notifications.success(this.$i18n.translate('Messages.State.SR_StateMessages.DeleteSuccess'))
          })
          .catch(error => {
            notifications.error(this.$i18n.translate('Messages.State.SR_StateMessages.DeleteError'))
            console.error(error)
          })
      })
    },
    toggleExpanded (id) {
      this.$store.commit('fieldRecordSystem/lanePlanning/toggleExpanded', id)
    },
    goToExport () {
      this.$store.commit('fieldRecordSystem/setRightView', 'lanePlanExport')
    }
  },
  watch: {
    orgUnitId () {
      this.resetLanePlansSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.help-box {
  margin: 0;

  &:hover {
    cursor: pointer;
    background: darken(#d1ecf1, 0.5%);
    border-color: darken(#bee5eb, 0.5%);
  }
}
.footer-container {
  display: flex;
  flex-direction: column;

  .button-container {
    margin-top: 12px;
    display: flex;

    .delete-button {
      margin-right: 4px;
    }
  }
}
</style>
