<template>
  <div class="frame-container">
    <WizardHeader :hide-back="!hideBack" @back="$emit('back')">
      <slot slot="title" name="title" />
      <slot slot="step" name="step" />
      <slot slot="hint" name="hint" />
    </WizardHeader>

    <div class="content">
      <slot />
    </div>

    <WizardFooter v-if="!hideFooter" :hide-cancel="!hideCancel" @next="$emit('next')">
      <slot v-if="$slots.nextButton" name="nextButton" />
    </WizardFooter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import WizardHeader from './WizardHeader'
import WizardFooter from './WizardFooter'

export default {
  components: {
    WizardHeader,
    WizardFooter
  },
  props: {
    hideCancel: {
      type: Boolean,
      default: false
    },
    hideBack: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      hideFooter: state => state.ui.hideFooter
    })
  }
}
</script>

<style lang="scss" scoped>
.frame-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin: 8px;
}
.content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column
}
::v-deep hr {
  margin: 8px 0;
  width: 100%;
  border-color: #cbcbcb;
}
</style>
