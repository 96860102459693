<template>
  <div class="lane-item-container">
    <template v-if="!editMode">
      <i class="fa fa-pencil" @click="editMode = true" />
      <a class="link-name" @click="showPlanOnMap()">{{ planName }}</a>
      <a class="link" @click="showDetails()"><IxRes>Areas.LanePlanning.SR_LanePlanning.ShowDetails</IxRes></a>
    </template>
    <template v-else class="edit-mode">
      <input v-model="editName" v-select>
      <i class="fa fa-check" :title="$i18n.translate('Areas.LanePlanning.SR_LanePlanning.Save')" @click="updateName" />
      <i class="fa fa-ban" :title="$i18n.translate('Areas.LanePlanning.SR_LanePlanning.Cancel')" @click="editMode = false" />
    </template>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import {notifications} from 'src/js/infrastructure'

export default {
  directives: {
    select: {
      inserted: function (el) {
        el.focus()
        el.select()
      }
    }
  },
  props: {
    content: Object
  },
  data () {
    return {
      editMode: false,
      editName: String
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      lanePlans: state => state.data.lanePlansOrgUnit
    }),
    planName () {
      return this.content.name ? this.content.name : this.$i18n.translate('Areas.LanePlanning.SR_LanePlanning.LanePlanName')
    },
    lanePlansField () {
      const plansForField = this.lanePlans[this.content.entityId]
      return plansForField.filter(plan => plan.id !== this.content.id)
    },
    nextFreeName () {
      let i = 0
      while (this.lanePlansField.find(x => i === 0 ? x.name === this.editName : x.name === `${this.editName}_${i}`)) {
        i++
      }

      return i === 0 ? this.editName : `${this.editName}_${i}`
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/lanePlanning', [
      'setSelectedLanePlan'
    ]),
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'updateLanePlanName'
    ]),
    showDetails () {
      this.setSelectedLanePlan(this.content)
      this.setRightView('lanePlanDetail')
      this.$nextTick(() => {
        this.$store.dispatch('fieldRecordSystem/map/focus', this.content.fieldGeometry)
      })
    },
    showPlanOnMap () {
      this.setSelectedLanePlan(this.content)
      this.$nextTick(() => {
        this.$store.dispatch('fieldRecordSystem/map/focus', this.content.fieldGeometry)
      })
    },
    updateName () {
      this.editMode = !this.editMode
      if (this.editName === this.planName) return
      this.updateLanePlanName({entityId: this.content.entityId, planId: this.content.id, name: this.nextFreeName})
        .then(() => {
          notifications.success(this.$i18n.translate('Messages.State.SR_StateMessages.UpdateSuccessful'))
          if (this.editName !== this.nextFreeName) {
            notifications.info(this.$i18n.translate('Messages.State.SR_StateMessages.AddSuffixToName'))
          }
          this.editName = this.planName
        })
        .catch(error => {
          notifications.error(this.$i18n.translate('Messages.State.SR_StateMessages.UpdateFailed'))
          console.error(error)
        })
    }
  },
  mounted () {
    this.editName = this.planName
  }
}
</script>

<style lang="scss" scoped>
.lane-item-container {
  width: 100%;
}
.edit-mode {
  display: flex;
  flex-direction: row;
}
.fa {
  font-size: 16px;
  padding-top: 2px;
  margin: 0 4px;
}
.fa:hover {
  cursor: pointer;
}
.link {
  margin-left: 4px;
  cursor: pointer;
}
.link-name {
  color: black;
  cursor: pointer;
}
.fa-check {
  color: green;
}
.fa-ban {
  color: darkred;
}
.fa-pencil:hover {
  color: black;
}
.fa-check:hover {
  color: lighten(green, 10%);
}
.fa-ban:hover {
  color: lighten(darkred, 20%);
}
.fa-pencil {
  color: lighten(black, 30%);
}
</style>
