<template lang="html">
  <WizardNavigationFrame @back="cancelPart" @next="generateHeadlands">
    <template #title>
      <IxRes>geotrax.wizard.title</IxRes>
    </template>
    <template #step>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step2Title</IxRes>
    </template>
    <template #hint>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step2Action</IxRes>
    </template>

    <template v-if="moreFieldPartsExist">
      <IxButton
        large
        class="skip-button"
        icon="angle-double-right"
        @click="skipPart"
      >
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SkipFieldPart</IxRes>
      </IxButton>
    </template>

    <div class="edit-segment">
      <HelpBox>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SpareSegmentsInfo</IxRes>
      </HelpBox>

      <div class="segmentation-buttons">
        <div>
          <IxButton large icon="edit" @click="isSplitSegmentMode = !isSplitSegmentMode">
            <IxRes v-if="isSplitSegmentMode">geotrax.buttons.stopManualSegmentAdjustment</IxRes>
            <IxRes v-else>Areas.LanePlanning.SR_LanePlanning.ButtonSpareSegment</IxRes>
          </IxButton>
        </div>

        <div v-if="isSplitSegmentMode" class="reset-segmentation-button">
          <IxButton large icon="history" @click="resetSegmentation">
            <IxRes>Areas.LanePlanning.SR_LanePlanning.ResetSegmentation</IxRes>
          </IxButton>
        </div>
      </div>
    </div>

    <HelpBox v-if="isSplitSegmentMode" type="warning">
      <IxRes>geotrax.hints.manualSegmentAdjustmentWarning</IxRes>
    </HelpBox>

    <template v-else>
      <HelpBox>
        <IxRes v-if="isPlanningProfileAB">geotrax.hints.segmentSelection</IxRes>
        <IxRes v-else>Areas.LanePlanning.SR_LanePlanning.SegmentSelectionInfo</IxRes>
      </HelpBox>

      <div class="segment-selection">
        <IxButton
          class="button-select" large
          icon="check-square-o"
          :disabled="isSplitSegmentMode" @click="selectAllSegments"
        >
          <IxRes>Areas.LanePlanning.SR_LanePlanning.SelectAll</IxRes>
        </IxButton>
        <IxRes v-if="isPlanningProfileAB">geotrax.hints.selectAllLinesAB</IxRes>
        <IxRes v-else>Areas.LanePlanning.SR_LanePlanning.SelectAllInfo</IxRes>
      </div>

      <div class="segment-selection">
        <IxButton
          class="button-select" large
          icon="square-o"
          :disabled="isSplitSegmentMode" @click="deselectAllSegments"
        >
          <IxRes>Areas.LanePlanning.SR_LanePlanning.SelectNothing</IxRes>
        </IxButton>
        <IxRes v-if="isPlanningProfileAB">geotrax.hints.deselectAllLinesAB</IxRes>
        <IxRes v-else>Areas.LanePlanning.SR_LanePlanning.SelectNothingInfo</IxRes>
      </div>
    </template>

    <template v-if="!isPlanningProfileAB">
      <div class="depth-lane">
        <HelpBox>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.HeadlandLanesInfo</IxRes>
        </HelpBox>
        <NumericInput
          v-model="depthInputValue"
          :disabled="isSplitSegmentMode"
          unit="m"
          @blur="setDepthToMultipleHeadlandLanes"
        >
          <template #label>
            <IxRes>Areas.LanePlanning.SR_LanePlanning.DepthInfo</IxRes>:
          </template>
        </NumericInput>
        <NumericInput
          v-model="headlandLanesInputValue"
          :disabled="isSplitSegmentMode"
        >
          <template #label>
            <IxRes>Areas.LanePlanning.SR_LanePlanning.LineCount</IxRes>:
          </template>
        </NumericInput>
      </div>
    </template>
    <LaneSuggestionVectorLayer>
      <HeadlandVectorLayer />
    </LaneSuggestionVectorLayer>
    <SegmentVectorLayer :select="!isSplitSegmentMode" :split="isSplitSegmentMode" />
  </WizardNavigationFrame>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'

import WizardNavigationFrame from './widgets/WizardNavigationFrame'
import SegmentVectorLayer from './widgets/SegmentVectorLayer'
import LaneSuggestionVectorLayer from './widgets/LaneSuggestionVectorLayer'
import HeadlandVectorLayer from './widgets/HeadlandVectorLayer'

export default {
  components: {
    HeadlandVectorLayer,
    LaneSuggestionVectorLayer,
    SegmentVectorLayer,
    WizardNavigationFrame,
    HelpBox,
    NumericInput,
    IxButton
  },
  data () {
    return {
      isSplitSegmentMode: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      workingWidth: state => state.laneEditBuffer.workingWidth,
      gap: state => state.laneEditBuffer.gap
    }),
    ...mapFormFields('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'depth',
      'headlandLanes',
      'isHeadlandGenerated'
    ]),
    ...mapFormFields('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'planningProfile'
    ]),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'isPlanningProfileAB',
      'moreFieldPartsExist'
    ]),
    headlandLanesInputValue: {
      get () {
        return this.headlandLanes
      },
      set (count) {
        this.depth = count * (this.workingWidth + this.gap / 2)
        this.headlandLanes = count
      }
    },
    depthInputValue: {
      get () {
        return this.depth
      },
      set (depth) {
        this.headlandLanes = Math.floor(depth / (this.workingWidth + this.gap / 2))
        this.depth = depth
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'cancelPart',
      'getHeadland',
      'finishPart',
      'resetSegmentation'
    ]),
    ...mapMutations('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'selectAllSegments',
      'deselectAllSegments'
    ]),
    generateHeadlands () {
      if (!this.isPlanningProfileAB) {
        this.getHeadland()
      }

      this.isHeadlandGenerated = !this.isHeadlandGenerated
    },
    skipPart () {
      this.finishPart(true)
        .then(this.depth = this.workingWidth + this.gap)
    },
    setDepthToMultipleHeadlandLanes () {
      this.depth = this.headlandLanes * (this.workingWidth + this.gap / 2)
    }
  },
  mounted () {
    this.depth = this.headlandLanes * (this.workingWidth + this.gap / 2)
  }
}
</script>

<style lang="scss" scoped>
.edit-segment {
  margin-bottom: 18px;
}
::v-deep .input-field {
  width: 40%;
}
.skip-button {
  margin-bottom: 16px;
}
.segment-selection {
  font-size: 13px;

  .button-select {
    width: 38%;
    margin-right: 2px;
    margin-bottom: 4px;
  }
}
.depth-lane {
  margin-top: 12px;
}
.segmentation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.reset-segmentation-button {
  margin-top: 3px;
}
</style>
