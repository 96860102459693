import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {unByKey} from 'ol/Observable'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import {geoJsonFormat} from '@helpers/openlayers/features'

const makeOverlay = (style) => new VectorLayer({
  source: new VectorSource({
    features: [],
    useSpatialIndex: false
  }),
  style,
  updateWhileAnimating: true,
  updateWhileInteracting: true
})

export default {
  inject: [
    'getMap'
  ],
  props: {
    value: {
      required: true
    },
    features: {
      type: Array,
      required: true
    },
    selectionStyle: {
      required: true
    },
    hoverStyle: {
      default: null
    },
    cursorStyle: {
      default: () => () => null // double lambda required since vue supports factory functions for prop defaults and openlayers treats a simple null value as no style and defaults to the layer style, which is not intended
    },
    hitTolerance: {
      type: Number,
      default: 0
    },
    layerFilter: Function,
    idKey: {
      type: String,
      default: 'id'
    },
    multiple: Boolean
  },
  data () {
    return {
      hoverFeature: null
    }
  },
  computed: {
    queryOptions () {
      const options = {
        hitTolerance: this.hitTolerance
      }

      if (this.layerFilter) {
        options.layerFilter = this.layerFilter
      }

      return options
    },
    cursor () {
      const feature = new Feature({
        geometry: new Point([0, 0])
      })
      feature.setStyle(this.cursorStyle)
      return feature
    },
    selectedFeatures () {
      const ids = this.multiple
        ? this.value
        : this.value !== null
          ? [this.value]
          : []

      return ids
        .map(id => this.features.find(x => x.properties[this.idKey] === id))
        .filter(feature => feature)
        .map(feature => geoJsonFormat.readFeature(feature))
    }
  },
  methods: {
    init () {
      // TODO make reactive
      this.selectionOverlay = makeOverlay(this.selectionStyle)
      this.hoverOverlay = makeOverlay(this.hoverStyle)

      this.selectionOverlay.setMap(this.map)
      this.hoverOverlay.setMap(this.map)

      this.listeners.push(this.map.on('pointermove', this.onMove))
      this.listeners.push(this.map.on('singleclick', this.onClick))

      this.rebuildHoverOverlay()
      this.rebuildSelectionOverlay()
    },
    rebuildHoverOverlay () {
      if (this.hoverOverlay) {
        this.hoverOverlay.getSource().clear()
        this.hoverOverlay.getSource().addFeature(this.cursor)
      }

      if (this.hoverFeature) {
        this.hoverOverlay.getSource().addFeature(this.hoverFeature)
      }
    },
    rebuildSelectionOverlay () {
      if (this.selectionOverlay) {
        this.selectionOverlay.getSource().clear()
        this.selectionOverlay.getSource().addFeatures(this.selectedFeatures)
      }
    },
    rebuildAll () {
      this.rebuildHoverOverlay()
      this.rebuildSelectionOverlay()
    },
    onMove (event) {
      const feature = this.map.forEachFeatureAtPixel(event.pixel, feature => feature, this.queryOptions)

      this.hoverFeature = feature || null

      this.cursor.getGeometry().setCoordinates(event.coordinate)
    },
    onClick (event) {
      const feature = this.map.forEachFeatureAtPixel(event.pixel, feature => feature, this.queryOptions)

      if (feature) {
        this.toggleFeature(feature)
      } else {
        this.$emit('input', this.multiple ? [] : null)
      }
    },
    toggleFeature (feature) {
      const id = feature.get(this.idKey)

      this.$emit('toggle', this.features.find(x => x.properties[this.idKey] === id))

      if (this.multiple) {
        const selection = [...this.value]

        const index = selection.indexOf(id)

        if (index > -1) {
          selection.splice(index, 1)
        } else {
          selection.push(id)
        }

        this.$emit('input', selection)
      } else {
        this.$emit('input', id !== this.value ? id : null)
      }
    }
  },
  watch: {
    cursor: 'rebuildHoverOverlay',
    features: 'rebuildAll',
    hoverFeature: 'rebuildHoverOverlay',
    value: 'rebuildSelectionOverlay'
  },
  render () {
    return null
  },
  created () {
    this.listeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
    })
  },
  beforeDestroy () {
    this.listeners.forEach(key => {
      unByKey(key)
    })
    if (this.selectionOverlay) {
      this.selectionOverlay.getSource().clear()
      this.selectionOverlay.setMap(null)

      this.hoverOverlay.getSource().clear()
      this.hoverOverlay.setMap(null)
    }
  }
}
