import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {transform} from 'ol/proj'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import PointerInteraction from 'ol/interaction/Pointer'

const makeOverlay = (style) => new VectorLayer({
  source: new VectorSource({
    features: [],
    useSpatialIndex: false
  }),
  style,
  updateWhileAnimating: true,
  updateWhileInteracting: true
})

export default {
  inject: [
    'getMap'
  ],
  props: {
    hitTolerance: {
      type: Number,
      default: 0
    },
    layerFilter: Function,
    hoverStyle: {
      default: null
    },
    cursorStyle: {
      default: null
    }
  },
  data () {
    return {
      hoverFeature: null
    }
  },
  computed: {
    queryOptions () {
      const options = {
        hitTolerance: this.hitTolerance
      }

      if (this.layerFilter) {
        options.layerFilter = this.layerFilter
      }

      return options
    },
    cursor () {
      const feature = new Feature({
        geometry: new Point([0, 0])
      })
      feature.setStyle(this.cursorStyle)
      return feature
    }
  },
  methods: {
    init () {
      this.overlay = makeOverlay(this.hoverStyle)
      this.overlay.setMap(this.map)

      this.interaction = new PointerInteraction({
        handleMoveEvent: this.onMove,
        handleDownEvent: this.onClick
      })
      this.map.addInteraction(this.interaction)

      this.rebuildOverlay()
    },
    rebuildOverlay () {
      this.overlay.getSource().clear()
      this.overlay.getSource().addFeature(this.cursor)

      if (this.hoverFeature) {
        this.overlay.getSource().addFeature(this.hoverFeature)
      }
    },
    getFeature (event) {
      return this.map.forEachFeatureAtPixel(event.pixel, feature => feature === this.cursor ? null : feature, this.queryOptions)
    },
    onMove (event) {
      const feature = this.getFeature(event)

      this.hoverFeature = feature || null

      this.cursor.getGeometry().setCoordinates(event.coordinate)
      this.cursor.set('feature', this.hoverFeature)
    },
    onClick (event) {
      const feature = this.getFeature(event)

      const coordinate = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')

      if (feature) {
        this.$emit('click', {feature, coordinate})
      } else {
        this.$emit('click', {feature: null, coordinate})
      }
    }
  },
  watch: {
    cursor: 'rebuildOverlay',
    hoverFeature: 'rebuildOverlay'
  },
  render () {
    return null
  },
  created () {
    this.getMap().then(map => {
      this.map = map
      this.init()
    })
  },
  beforeDestroy () {
    if (this.interaction) {
      this.map.removeInteraction(this.interaction)
    }

    if (this.overlay) {
      this.overlay.getSource().clear()
      this.overlay.setMap(null)
    }
  }
}
