<template lang="html">
  <WizardNavigationFrame @next="startPlanning">
    <template #title>
      <IxRes>geotrax.wizard.title</IxRes>
    </template>
    <template #step>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step1Title</IxRes>
    </template>
    <template #hint>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step1Action</IxRes>
    </template>

    <IxButton large edit @click="startEditingFieldGeometry({features})">
      <IxRes>Areas.LanePlanning.SR_LanePlanning.ButtonSpareField</IxRes>
    </IxButton>

    <VueSelectize
      v-model="planningProfile"
      class="planning-profile"
      :options="planningProfiles" :fields="planningProfileFields"
    >
      <template #label>
        <IxRes>geotrax.labels.planningProfile</IxRes>:
      </template>
    </VueSelectize>

    <MachineSettings v-if="!isPlanningProfileAB" />
  </WizardNavigationFrame>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import parse from 'wellknown'

import {mapFormFields} from '@helpers/vuex'

import PermissionMixin from '@mixins/PermissionMixin'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'

import MachineSettings from './widgets/MachineSettings'
import WizardNavigationFrame from './widgets/WizardNavigationFrame'

export default {
  components: {
    WizardNavigationFrame,
    MachineSettings,
    VueSelectize,
    IxButton
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      planningProfiles: state => state.data.planningProfiles
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldWkt'
    ]),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'isPlanningProfileAB'
    ]),
    ...mapFormFields('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'planningProfile',
      'turningCircle'
    ]),
    planningProfileFields () {
      return {
        text: x => this.$i18n.translate(`geotrax.planningProfiles.${x}`),
        value: x => x
      }
    },
    features () {
      return [{
        geometry: parse(this.fieldWkt),
        type: 'Feature'
      }]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'startEditingFieldGeometry',
      'startPlanningPart'
    ]),
    startPlanning () {
      this.turningCircle = 1
      this.startPlanningPart()
    }
  }
}
</script>

<style lang="scss" scoped>
.planning-profile {
  margin-top: 10px;
  width: 80%;
}
.selection-action {
  width: 80%;
}
.help-box {
  margin-top: 8px;
  margin-bottom: 4px;
}
</style>
