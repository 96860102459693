<template>
  <div>
    <IxVectorLayer
      :features="laneFeatures"
      :vector-style="styles.laneStyle"
      layer-id="laneFeatures"
    />
    <IxVectorLayer
      :features="headlandLaneFeatures"
      :vector-style="styles.headlandLaneStyle"
      layer-id="headlandLaneFeatures"
    />
    <IxVectorLayer
      :features="headlandFeatures"
      :vector-style="styles.headlandStyle"
      layer-id="headlandFeatures"
    />
    <IxVectorLayer
      :features="partBorderFeatures"
      :vector-style="styles.borderStyle"
      layer-id="partBorderFeatures"
    />

    <IxVectorLayer
      :features="partBorderFeaturesAb"
      :vector-style="styles.partialFieldStyle"
      layer-id="partBorderAbFeatures"
    />
    <IxVectorLayer
      :features="abFeatures"
      :vector-style="styles.abStyle"
      layer-id="abFeatures"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import parse from 'wellknown'

import {headland, headlandLane, lane, fieldBorder, aBSegment, partialFieldStyle} from '@frs/components/lane-planning/styles'

import IxVectorLayer from '@components/map/IxVectorLayer'
export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      plan: state => state.data.selectedPlan
    }),
    laneFeatures () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('partialFields')) return []

      const lanes = this.plan.partialFields.map(part => part.lane).filter(x => x)
      return lanes.length
        ? lanes.map((lane, index) => ({
          geometry: parse(lane.lineGeometries),
          type: 'Feature',
          properties: {
            id: `lane_${index}`
          }
        }))
        : []
    },
    headlandLaneFeatures () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('partialFields')) return []

      const headlandLanes = this.plan.partialFields.map(part => part.headland).filter(x => x)
      return headlandLanes.length
        ? headlandLanes.map((lane, index) => ({
          geometry: parse(lane.laneGeometry),
          type: 'Feature',
          properties: {
            id: `headlandLane_${index}`
          }
        }))
        : []
    },
    headlandFeatures () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('partialFields')) return []

      const headlands = this.plan.partialFields.map(part => part.headland).filter(x => x)
      return headlands.length
        ? headlands.map((headland) => headland.geometries.map((wkt, index) => ({
          geometry: parse(wkt),
          type: 'Feature',
          properties: {
            id: `headland_${index}`
          }
        }))).flat()
        : []
    },
    partBorderFeatures () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('partialFields')) return []

      const geometries = this.plan.partialFields.map(part => part.geometry).filter(x => x)
      return geometries.length
        ? geometries.map((geometry, index) => ({
          geometry: parse(geometry),
          type: 'Feature',
          properties: {
            id: `partBorder_${index}`
          }
        }))
        : []
    },
    partBorderFeaturesAb () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('abLine')) return []

      return [{
        geometry: parse(this.plan.fieldPartGeometry),
        type: 'Feature',
        properties: {
          id: 'partBorder'
        }
      }]
    },
    abFeatures () {
      if (!this.plan) return []
      if (!this.plan.hasOwnProperty('abLine')) return []

      return [{
        geometry: parse(this.plan.abLine),
        type: 'Feature',
        properties: {
          id: 'abLine'
        }
      }]
    },
    styles () {
      return {
        laneStyle: lane,
        headlandLaneStyle: headlandLane,
        headlandStyle: headland,
        borderStyle: fieldBorder,
        partialFieldStyle: partialFieldStyle,
        abStyle: aBSegment
      }
    }
  }
}
</script>

<style scoped>

</style>
