<template>
  <div class="creation-container">
    <HelpBox>
      <IxRes>geotrax.hints.createAbLine</IxRes>
    </HelpBox>

    <div class="button-container">
      <IxButton
        v-if="line"
        class="button-item"
        large
        @click="line = null"
      >
        <IxRes>geotrax.labels.discardLine</IxRes>
      </IxButton>
      <SimpleSwitch v-model="snappingMode" class="switch-item">
        <IxRes>geotrax.labels.snapping</IxRes>
      </SimpleSwitch>
    </div>
    <div class="button-container">
      <IxButton
        class="button-item"
        large
        cancel
        @click="$emit('finish')"
      />
      <IxButton
        v-if="line"
        class="button-item"
        large
        add
        @click="addAbLine"
      >
        <IxRes>geotrax.labels.addAbLine</IxRes>
      </IxButton>
    </div>

    <IxVectorLayer
      :features="fieldBorderFeatures"
      :vector-style="styles.border"
      layer-id="fieldBorder"
      auto-focus
    />
    <IxVectorLayer
      :features="lineFeature"
      :vector-style="styles.abLine"
      layer-id="fieldBorder"
      auto-focus
    />
    <DrawInteraction
      v-if="!line"
      type="LineString"
      @finish="onDrawFinish"
    />
    <SnapInteraction
      v-if="snappingMode"
      :features="fieldBorderFeatures"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {parse, stringify} from 'wellknown'
import {abLine, border} from '@frs/components/lane-planning/styles'
import {createPlanName} from '@store/modules/field-record-system/lane-planning/helpers'

import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import DrawInteraction from '@components/map/interactions/DrawInteraction'
import SnapInteraction from '@components/map/interactions/SnapInteraction'
import IxVectorLayer from '@components/map/IxVectorLayer'
import SimpleSwitch from '@components/forms/SimpleSwitch'

export default {
  components: {
    SimpleSwitch,
    IxButton,
    HelpBox,
    DrawInteraction,
    SnapInteraction,
    IxVectorLayer
  },
  data () {
    return {
      line: null,
      snappingMode: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'abSegments',
      'selectedAbSegment'
    ]),
    ...mapState('fieldRecordSystem/lanePlanning', {
      lanePlanNamesForField: state => state.data.lanePlanNamesForField
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldWkt',
      'fieldWktsFromOrgUnit',
      'fieldName'
    ]),
    fieldBorderFeatures () {
      return (this.fieldWkt
        ? [{geometry: parse(this.fieldWkt), type: 'Feature', properties: {...this.fieldWkt}}]
        : this.fieldWktsFromOrgUnit.map(wkt => ({geometry: parse(wkt.wkt), type: 'Feature', properties: {...wkt}})))
    },
    lineFeature () {
      if (!this.line) return []
      return [this.line]
    },
    styles () {
      return {
        border,
        abLine
      }
    }
  },
  methods: {
    onDrawFinish (feature) {
      this.line = feature
    },
    addAbLine () {
      let names = this.abSegments.map(feature => feature.properties.name)
      names = names.concat(this.lanePlanNamesForField)
      const globalSegmentIndices = this.abSegments.map(feature => feature.properties.globalSegmentIndex)
      const globalSegmentIndex = globalSegmentIndices.length ? Math.max(...globalSegmentIndices) + 1 : 0

      this.line.properties = {
        name: createPlanName(names, `${this.fieldName}_AB`),
        arrayIndex: this.abSegments.length,
        lineType: this.line.geometry.coordinates.length > 2 ? 'contour' : 'line',
        globalSegmentIndex,
        computed: false,
        segmentData: {
          lineString: stringify(this.line.geometry)
        }
      }
      this.selectedAbSegment = globalSegmentIndex
      this.abSegments.push(this.line)

      return this.$emit('finish')
    }
  }
}
</script>

<style lang="scss" scoped>
.creation-container {
  display: flex;
  flex-direction: column;

  .button-container {
    display:flex;

    .button-item {
      margin-right: 6px;
      margin-bottom: 6px;
    }
    .switch-item {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}
</style>
