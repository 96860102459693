<template>
  <div class="tree-container">
    <div class="component-container">
      <i v-if="showExpandedIcon" :class="`fa ${getExpandedIcon}`" @click="$emit('toggle', node.id)" />
      <component
        :is="renderComponent"
        :class="hasExpandedIcon"
        :children="flattenChildren"
        :content="node.content"
      />
    </div>
    <transition name="children">
      <transition-group
        v-if="hasChildren && node.expanded" tag="div"
        name="child"
        class="children" appear
      >
        <IxTree
          v-for="child in node.children"
          :key="child.id"
          :node="child"
          :render-component="renderComponent"
          @toggle="$emit('toggle', $event)"
        />
      </transition-group>
    </transition>
  </div>
</template>

<script>
import {flattenEntityForest} from '@helpers/tree'

export default {
  name: 'IxTree',
  props: [
    'node',
    'renderComponent'
  ],
  data () {
    return {
      showExpandedIcon: true
    }
  },
  computed: {
    hasChildren () {
      return this.node && this.node.children && this.node.children.length > 0
    },
    hasExpandedIcon () {
      return this.showExpandedIcon ? 'item' : 'item-without-carrot'
    },
    getExpandedIcon () {
      return this.node.expanded ? 'fa-caret-down' : 'fa-caret-right'
    },
    flattenChildren () {
      return this.node.children ? flattenEntityForest(this.node.children) : []
    }
  },
  mounted () {
    if (!this.node.children) {
      this.showExpandedIcon = false
    }
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
}
.children {
  padding-left: 21px;
  width: 100%
}
.component-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.fa-caret-down, .fa-caret-right {
  padding-top: 4px;
  font-size: 16px;
}
.fa-caret-down:hover, .fa-caret-right:hover {
  cursor: pointer;
}
.item {
  padding-left: 6px;
  width: 100%;
}
.item-without-carrot {
  padding-left: 12px;
  width: 100%
}
</style>
