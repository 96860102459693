<template lang="html">
  <div class="form-group">
    <NumericInput
      :label="label" :value="value.amount"
      :required="required"
      name="zoneInput"
      :rule="rule"
      placement
      @input="onInput"
    >
      <slot slot="label" name="label" />
      <div slot="inputGroup" class="input-group-btn">
        <button
          type="button" class="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"
        >
          <span>{{ $i18n.translateUnit(value.unit) }}</span>
          <span class="caret" />
        </button>
        <ul class="dropdown-menu dropdown-menu-right">
          <li v-for="unit in unitOptions" :key="unit">
            <a @click="onUnitChange(unit)">
             <span>{{ $i18n.translateUnit(unit) }}</span>
            </a>
          </li>
        </ul>
      </div>
    </NumericInput>
  </div>
</template>

<script>
import NumericInput from './NumericInput'

export default {
  components: {
    NumericInput
  },
  props: {
    label: String,
    unitOptions: Array,
    value: Object,
    required: Boolean,
    rule: Function,
    placement: String
  },
  methods: {
    onInput (amount) {
      this.$emit('input', {...this.value, amount})
    },
    onUnitChange (unit) {
      this.$emit('input', {...this.value, unit})
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.dropdown-menu > li > a:hover {
  background-color: #DDD;
}
</style>
