<template lang="html">
  <div class="lane-suggestions">
    <HelpBox>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.LaneSuggestionsInfo</IxRes>
    </HelpBox>
    <div class="suggestion-container">
      <span class="suggestion-text">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.Suggestions</IxRes>
      </span>

      <SimpleTable
        :highlight="(x, i) => i === featureSegment[0].properties.arrayIndex ? ['warning'] : []"
        :columns="simpleColumns"
        :data="tableData"
      >
        <template slot="select" slot-scope="{rowIndex}">
          <a href="#" @click.prevent="selectSegment(rowIndex)">
            <IxRes>Areas.LanePlanning.SR_LanePlanning.Choose</IxRes>
          </a>
        </template>
        <template slot="name" slot-scope="{rowIndex}">
          <input v-model="abSegments[rowIndex].properties.name" type="text">
        </template>
      </SimpleTable>
    </div>

    <IxButton
      v-if="!isCreateAbLineActive"
      large
      add
      @click="isCreateAbLineActive = true"
    >
      <IxRes>Areas.LanePlanning.SR_LanePlanning.NewABLine</IxRes>
    </IxButton>

    <CreateABLine v-if="isCreateAbLineActive" @finish="isCreateAbLineActive = false" />

    <IxVectorLayer
      :features="abSegments"
      :vector-style="vectorStyle"
      layer-id="abLines"
    />
    <IxVectorLayer
      :features="featureSegment"
      :vector-style="segmentStyle"
      layer-id="segments"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {stringify, parse} from 'wellknown'
import {createPlanName} from '@store/modules/field-record-system/lane-planning/helpers'

import {abPreview, selectedABSegment} from '@frs/components/lane-planning/styles'

import SimpleTable from '@components/table/SimpleTable'
import HelpBox from '@components/help/HelpBox'
import IxVectorLayer from '@components/map/IxVectorLayer'
import IxButton from '@components/IxButton'

import CreateABLine from './CreateABLine'

export default {
  components: {
    HelpBox,
    SimpleTable,
    IxVectorLayer,
    IxButton,
    CreateABLine
  },
  data () {
    return {
      simpleColumns: {
        index: {title: '#', type: 'number', value: (x, i) => x.index},
        type: {title: 'Type', type: 'string', value: x => this.$i18n.translate(`geotrax.lineTypes.ab_${x.type}`)},
        name: {title: 'Name', type: 'string', value: x => x.name},
        select: ''
      }
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/lanePlanning', [
      'ui.isCreateAbLineActive'
    ]),
    ...mapFormFields('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'selectedAbSegment',
      'abSegments'
    ]),
    ...mapState('fieldRecordSystem/lanePlanning', {
      lanePlanNamesForField: state => state.data.lanePlanNamesForField
    }),
    ...mapState('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'segmentationResult',
      'selectedSegments',
      'segmentLaneTypes'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'fieldName'
    ]),
    tableData () {
      return this.abSegments.map(feature => {
        return {
          index: feature.properties.globalSegmentIndex,
          name: feature.properties.name,
          type: feature.properties.lineType
        }
      })
    },
    features () {
      const removeInnerPoints = coordinates => [
        coordinates[0],
        coordinates[coordinates.length - 1]
      ]
      const selectedSegments = this.selectedSegments
      const segmentLaneTypes = this.segmentLaneTypes
      let names = this.lanePlanNamesForField
      let arrayIndex = 0

      return this.segmentationResult.reduce((features, ring, ringIndex) => {
        return ring.reduce((features, segment, segmentIndex) => {
          if (selectedSegments[ringIndex].includes(segmentIndex)) {
            const segmentGeometry = parse(segment.lineString)

            const lineType = segmentLaneTypes[ringIndex][segmentIndex]

            const geometry = lineType === 'line'
              ? {
                ...segmentGeometry,
                coordinates: removeInnerPoints(segmentGeometry.coordinates)
              }
              : segmentGeometry

            const name = createPlanName(names, `${this.fieldName}_AB`)
            names.push(name)

            features.push({
              geometry,
              type: 'Feature',
              properties: {
                segmentData: {
                  endIndex: segment.endIndex,
                  indexOfParentRing: segment.indexOfParentRing,
                  lineString: stringify(geometry),
                  startIndex: segment.startIndex
                },
                computed: true,
                id: `segment_${ringIndex}_${segmentIndex}`,
                arrayIndex,
                ringIndex,
                segmentIndex,
                globalSegmentIndex: 1 + ringIndex + segmentIndex,
                lineType,
                name
              }
            })
            arrayIndex++
          }
          return features
        }, features)
      }, [])
    },
    featureSegment () {
      if (!this.abSegments.length) return []
      const selectedFeature = this.abSegments.find(segment => segment.properties.globalSegmentIndex === this.selectedAbSegment)
      return [selectedFeature]
    },
    vectorStyle () {
      return abPreview
    },
    segmentStyle () {
      return selectedABSegment
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'getLanePlanNamesForField'
    ]),
    selectSegment (rowIndex) {
      this.selectedAbSegment = this.abSegments[rowIndex].properties.globalSegmentIndex
    }
  },
  watch: {
    features: {
      immediate: true,
      handler (value) {
        this.abSegments = value
        if (value.length) {
          this.selectedAbSegment = value[0].properties.globalSegmentIndex
        }
      }
    }
  },
  beforeMount () {
    this.getLanePlanNamesForField(this.fieldId)
  }
}
</script>

<style lang="scss" scoped>
.suggestion-container {
  position: relative;
  border: 2px solid #cbcbcb;
  padding: 8px;
  margin: 16px 0;

  .suggestion-text {
    font-size: 0.8em;
    position: absolute;
    top: -29px;
    left: 0px;
    background-color: #f4f6f4;
    margin: 16px 0 0 8px;
    width: 60px;
    padding: 4px;
  }

  ::v-deep table {
    margin-bottom: 0;
  }
}
</style>
