<template>
  <div>
    <IxVectorLayer
      :features="featureSegment"
      :vector-style="styles.segment"
      layer-id="borderSegments"
    />
    <SelectInteraction
      v-if="select"
      v-model="selectedSegmentIds"
      :features="featureSegment"
      :layer-filter="layer => layer.get('id') === 'borderSegments'"
      :hit-tolerance="10"
      :selection-style="styles.selectedSegment"
      :hover-style="styles.segmentHover"
      multiple
    />
    <template v-else>
      <ClickInteraction
        :cursor-style="styles.splitCursor"
        @click="onSplitClick"
      />

      <SnapInteraction
        :features="featureSegment"
      />
    </template>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {makeSegmentFeatures} from 'src/js/store/modules/field-record-system/lane-planning/helpers'

import helpers from '@turf/helpers'
import {stringify} from 'wellknown'

import {segmentHover, selectedSegment, segment, splitCursor} from '@frs/components/lane-planning/styles'

import IxVectorLayer from '@components/map/IxVectorLayer'
import SelectInteraction from '@components/map/interactions/SelectInteraction'
import SnapInteraction from '@components/map/interactions/SnapInteraction'
import ClickInteraction from '@components/map/interactions/ClickInteraction'

export default {
  components: {
    IxVectorLayer,
    SelectInteraction,
    SnapInteraction,
    ClickInteraction
  },
  props: {
    select: Boolean,
    split: Boolean,
    filter: {
      type: Function,
      default: () => () => true
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      partEditBuffer: state => state.partEditBuffer
    }),
    featureSegment () {
      return makeSegmentFeatures(this.partEditBuffer.segmentationResult, this.partEditBuffer.segmentLaneTypes)
    },
    styles () {
      return {
        selectedSegment,
        segmentHover,
        segment,
        splitCursor
      }
    },
    selectedSegmentIds: {
      get () {
        const segmentFeatures = makeSegmentFeatures(this.partEditBuffer.segmentationResult, this.partEditBuffer.segmentLaneTypes)
        return segmentFeatures.filter(feature => {
          const {ringIndex, segmentIndex} = feature.properties
          return this.partEditBuffer.selectedSegments[ringIndex].includes(segmentIndex)
        }).map(feature => feature.properties.id)
      },
      set (segmentIds) {
        this.findAndSetSelectedSegments(segmentIds)
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'splitOrJoinSegment'
    ]),
    ...mapActions('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'findAndSetSelectedSegments'
    ]),
    onSplitClick ({coordinate}) {
      const point = helpers.point(coordinate)
      // const mercatorPoint = projection.toMercator(point)
      // const mercatorPointWkt = stringify(mercatorPoint)

      this.splitOrJoinSegment(stringify(point))
    }
  }
}
</script>

<style scoped>

</style>
