<template lang="html">
  <div class="lane-suggestions">
    <HelpBox>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.LaneSuggestionsInfo</IxRes>
    </HelpBox>
    <div class="suggestion-container">
      <span class="suggestion-text">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.Suggestions</IxRes>
      </span>

      <SimpleTable :columns="simpleColumns" :data="lanes" :highlight="(x, i) => i === selectedLane ? ['warning'] : []">
        <template slot="select" slot-scope="{rowIndex}">
          <a href="#" @click.prevent="selectCandidate(rowIndex)">
            <IxRes>Areas.LanePlanning.SR_LanePlanning.Choose</IxRes>
          </a>
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex'

import SimpleTable from '@components/table/SimpleTable'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox,
    SimpleTable
  },
  props: {
    lanes: Array, // list of candidate lanes
    value: Number // selected lane
  },
  data () {
    return {
      simpleColumns: {
        index: {title: '#', type: 'number', value: (x, i) => this.globalIndices[i]},
        turnsPerDistance: {title: 'TPD', type: 'number'},
        totalDistance: {title: 'Länge', type: 'number'},
        type: {title: 'Fahrspurtyp', type: 'string', value: x => this.$i18n.translate(`Enums.SR_Enums.LaneType_${x.type.name}`)},
        warnings: {title: 'Warnung', type: 'number', value: x => x.warnings},
        select: ''
      }
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning/partEditBuffer', {
      fieldId: state => state.fieldId,
      selectedLane: state => state.selectedLane,
      segmentationResult: state => state.segmentationResult
    }),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'globalSegmentIndicesForCandidateLanes'
    ]),
    globalIndices () {
      let indices = []
      for (let i = 0; i < this.lanes.length; i++) {
        const lane = this.lanes[i]
        indices.push(this.globalSegmentIndicesForCandidateLanes[lane.ringIndex][lane.segmentIndex])
      }
      return indices
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'set'
    ]),
    selectCandidate (index) {
      this.set({selectedLane: index})
    }
  }
}
</script>

<style lang="scss" scoped>
.suggestion-container {
  position: relative;
  border: 2px solid #cbcbcb;
  padding: 8px;
  margin: 16px 0 16px 0;

  .suggestion-text {
    font-size: 0.8em;
    position: absolute;
    top: -29px;
    left: 0px;
    background-color: #f4f6f4;
    margin: 16px 0 0 8px;
    width: 60px;
    padding: 4px;
  }
}
</style>
