<template lang="html">
  <WizardNavigationFrame @next="getDataForStepLaneSelection" @back="getBackToHeadlandGeneration">
    <template #title>
      <IxRes>geotrax.wizard.title</IxRes>
    </template>
    <template #step>
      <IxRes>geotrax.steps.infieldSettings</IxRes>
    </template>
    <template>
      <HelpBox>
        <IxRes>Areas.LanePlanning.SR_LanePlanning.ABLineInfo</IxRes>
      </HelpBox>
      <div class="image-container">
        <img
          :src="icons.line"
          :alt="$i18n.translate('geotrax.images.altLine')"
          class="ab-images"
        >
        <IxButton large @click="setAllSegmentsToLine">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.ButtonABLine</IxRes>
        </IxButton>
      </div>
      <div>
        <img
          :src="icons.curve"
          :alt="$i18n.translate('geotrax.images.altCurve')"
          class="ab-images"
        >
        <IxButton large @click="setAllSegmentsToContour">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.ButtonABContur</IxRes>
        </IxButton>
      </div>
      <ClickInteraction
        :layer-filter="layer => layer.get('id') === 'borderSegments'"
        :hit-tolerance="5"
        :hover-style="style.segmentABHover"
        @click="onMapClick"
      />
      <HeadlandVectorLayer />
      <IxVectorLayer
        :features="featureSelectedSegment"
        :vector-style="style.segmentAB"
        layer-id="borderSegments"
        auto-focus
      />
    </template>
  </WizardNavigationFrame>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {makeSegmentFeatures} from 'src/js/store/modules/field-record-system/lane-planning/helpers'
import {mapFormFields} from '@helpers/vuex'
import {segmentABHover, segmentAB} from '../styles'
import imgCurve from '@images/contour/kontur-rahmen.png'
import imgLine from '@images/contour/linie-rahmen.png'

import IxButton from '@components/IxButton'
import ClickInteraction from '@components/map/interactions/ClickInteraction'
import HelpBox from '@components/help/HelpBox'

import WizardNavigationFrame from './widgets/WizardNavigationFrame'
import HeadlandVectorLayer from './widgets/HeadlandVectorLayer'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer,
    HelpBox,
    WizardNavigationFrame,
    ClickInteraction,
    HeadlandVectorLayer,
    IxButton
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'selectedABSegments',
      'segmentLaneTypes',
      'isHeadlandGenerated'
    ]),
    ...mapState('fieldRecordSystem/lanePlanning', {
      partEditBuffer: state => state.partEditBuffer
    }),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'isPlanningProfileAB'
    ]),
    featureSelectedSegment () {
      const features = makeSegmentFeatures(this.partEditBuffer.segmentationResult, this.partEditBuffer.segmentLaneTypes)
      return this.isPlanningProfileAB
        ? features.filter(feature => this.partEditBuffer.selectedSegments[feature.properties.ringIndex].includes(feature.properties.segmentIndex))
        : features
    },
    style () {
      return {
        segmentABHover,
        segmentAB
      }
    },
    icons () {
      return {
        line: imgLine,
        curve: imgCurve
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'getSegmentation',
      'getDataForStepLaneSelection'
    ]),
    setAllSegmentsToLine () {
      this.segmentLaneTypes = this.partEditBuffer.segmentationResult.map(ring => ring.map(() => 'line'))
    },
    setAllSegmentsToContour () {
      this.segmentLaneTypes = this.partEditBuffer.segmentationResult.map(ring => ring.map(() => 'contour'))
    },
    getBackToHeadlandGeneration () {
      this.getSegmentation()
      this.isHeadlandGenerated = !this.isHeadlandGenerated
    },
    onMapClick ({feature}) {
      if (!feature) return

      const ringIndex = feature.get('ringIndex')
      const segmentIndex = feature.get('segmentIndex')
      const lineType = feature.get('lineType')
      // const {ringIndex, segmentIndex, lineType} = feature.properties

      const newLineType = lineType === 'line' ? 'contour' : 'line'

      feature.set('lineType', newLineType)
      // feature.properties.lineType = newLineType

      const newSegmentLaneTypes = this.segmentLaneTypes.map(types => [...types])
      newSegmentLaneTypes[ringIndex][segmentIndex] = newLineType

      this.segmentLaneTypes = newSegmentLaneTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  margin-bottom: 4px;
}
.ab-images {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 4px;
}
</style>
