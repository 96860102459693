<template lang="html">
  <FrsModuleRoot
    :translation-namespaces="translationNamespaces"
    :setup="setup"
    class="lane-planning-root"
  >
    <LanePlanning v-if="view === 'lanePlanning'" />
    <LanePlanSelectionOrgUnit v-else />
    <FrsLoadingIndicator :requests="['geotrax.segmentation', 'geotrax.headland', 'geotrax.laneSuggestions', 'geotrax.moveLane', 'geotrax.editSegments']" />
    <FrsLoadingIndicator saving :requests="['geotrax.savePlan', 'geotrax.savePlanAb']" />
  </FrsModuleRoot>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'

import LanePlanning from './LanePlanning'
import LanePlanSelectionOrgUnit from './manage-lane-plans/LanePlanSelectionOrgUnit'
import FrsLoadingIndicator from '../base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator,
    FrsModuleRoot,
    LanePlanning,
    LanePlanSelectionOrgUnit
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      view: state => state.ui.view.right
    }),
    ...mapState('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'fieldParts'
    ]),
    translationNamespaces () {
      return [
        'geotrax',
        'Areas.LanePlanning'
      ]
    },
    setup () {
      if (this.view === 'lanePlanning') {
        if (!this.fieldParts.length) {
          return this.startPlanning
        }

        return undefined
      } else {
        return this.loadPrerequisiteDataForOverview
      }
    },
    module () {
      return () => import('@store/modules/field-record-system/lane-planning')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'startPlanning',
      'loadPrerequisiteDataForOverview'
    ])
  }
}
</script>

<style lang="scss" scoped>
.lane-planning-root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
</style>
