<template lang="html">
  <div class="header-container">
    <div class="title">
      <slot name="title" />
    </div>
    <div class="step">
      <slot name="step" />
    </div>
    <div class="hint">
      <slot name="hint" />
    </div>

    <div class="button-container">
      <IxButton
        v-if="$listeners.back &&hideBack" large
        back
        @click="$emit('back')"
      />
    </div>
    <hr>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    hideBack: Boolean
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.4em;
    font-weight: bold;
  }
  .hint {
    font-size: 0.9em;
    margin-bottom: 6px;
    color: #969696;
  }
}
</style>
