<template>
  <div>
    <IxVectorLayer
      :features="featureLane"
      :vector-style="style.lane"
      layer-id="lanes"
    />
    <slot />
    <IxVectorLayer
      :features="featureSegment"
      :vector-style="style.segment"
      layer-id="segments"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {makeParts, makeSegmentFeatures, makeLaneFeatures, makeSelectedCandidateLane} from 'src/js/store/modules/field-record-system/lane-planning/helpers'

import {lane, segment} from '@frs/components/lane-planning/styles'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      partEditBuffer: state => state.partEditBuffer,
      laneEditBuffer: state => state.laneEditBuffer
    }),
    featureSegment () {
      return makeSegmentFeatures(this.partEditBuffer.segmentationResult, this.partEditBuffer.segmentLaneTypes)
    },
    featureLane () {
      const {selectedPartIndex, fieldParts} = this.laneEditBuffer
      const parts = makeParts(this.partEditBuffer, selectedPartIndex, fieldParts)
      const laneFeatures = makeLaneFeatures(selectedPartIndex, parts, this.partEditBuffer)
      const selectedCandidateLane = makeSelectedCandidateLane(this.partEditBuffer)
      if (selectedCandidateLane) laneFeatures.push(selectedCandidateLane)

      return laneFeatures
    },
    style () {
      return {
        segment,
        lane
      }
    }
  }
}
</script>

<style scoped>
</style>
