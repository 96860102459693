<template lang="html">
  <div class="footer-container">
    <hr>
    <div class="button-container">
      <IxButton
        v-if="hideCancel"
        large
        cancel
        @click="stopPlanning"
      >
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </IxButton>
      <slot>
        <IxButton
          large next
          @click="$emit('next')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    hideCancel: Boolean
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'stopPlanning'
    ])
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  flex-direction: column;

  .button-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
}
</style>
