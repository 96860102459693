<template lang="html">
  <component :is="component" :key="component" />
</template>

<script>
import {mapState} from 'vuex'

import CommonSettings from './CommonSettings'
import BorderSegmentSettings from './BorderSegmentSettings'
import InfieldSettings from './InfieldSettings'
import LaneSelection from './LaneSelection'
import ResultPage from './ResultPage'

export default {
  components: {
    CommonSettings,
    BorderSegmentSettings,
    InfieldSettings,
    LaneSelection,
    ResultPage
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      isFromDetailsView: state => state.ui.selection.isFromDetailsView
    }),
    component () {
      const {laneEditBuffer, partEditBuffer} = this.$store.state.fieldRecordSystem.lanePlanning

      if (!this.isFromDetailsView) {
        if (laneEditBuffer.finished) {
          return 'ResultPage'
        }
        if (partEditBuffer.candidateLanes.length || partEditBuffer.subStep === 'preview') {
          return 'LaneSelection'
        }
        if (partEditBuffer.isHeadlandGenerated) {
          return 'InfieldSettings'
        }
        if (partEditBuffer.wkt) {
          return 'BorderSegmentSettings'
        }

        return 'CommonSettings'
      }

      return 'ResultPage'
    }
  },
  watch: {
    component (value) {
      this.$emit('step', value)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
