import kinks from '@turf/kinks'
import {unByKey} from 'ol/Observable'
import Draw from 'ol/interaction/Draw'

import {geoJsonFormat} from '@helpers/openlayers/features'

export default {
  inject: [
    'getMap'
  ],
  props: {
    type: {
      type: String,
      validator: type => ['Polygon', 'LineString', 'Point'].indexOf(type) !== -1
    }
  },
  methods: {
    init () {
      this.drawInteraction = new Draw({
        type: this.type,
        freehandCondition: () => false
      })
      this.map.addInteraction(this.drawInteraction)

      this.listeners.push(this.drawInteraction.on('drawstart', this.onDrawStart))
      this.listeners.push(this.drawInteraction.on('drawend', this.onDrawEnd))

      document.addEventListener('keydown', this.onKeyDown)
    },
    onDrawStart () {
      this.cancelled = false
      document.addEventListener('keydown', this.onKeyDown)
    },
    onDrawEnd (event) {
      document.removeEventListener('keydown', this.onKeyDown)

      const feature = geoJsonFormat.writeFeatureObject(event.feature)

      if (feature.geometry.type !== 'Point') {
        if (kinks(feature).features.length > 0) {
          this.$emit('error', 'invalid geometry')
          this.cancelled = true
        }
      }

      if (!this.cancelled) {
        this.$emit('finish', feature)
      }
    },
    onKeyDown (event) {
      if (event.keyCode === 27) { // esc
        this.cancelled = true
        try {
          this.drawInteraction.finishDrawing()
        } catch (error) {
          console.error(error)
        }
      }
    }
  },
  render () {
    return null
  },
  created () {
    this.listeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
      // setTimeout(this.init, 2000)
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeyDown)

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction)
    }

    this.listeners.forEach(unByKey)
  }
}
