<template lang="html">
  <WizardNavigationFrame hide-cancel hide-back>
    <template #title>
      <IxRes>geotrax.wizard.title</IxRes>
    </template>
    <template #step>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step5Action</IxRes>
    </template>

    <template>
      <HelpBox v-if="!isFromDetailsView && isFromField" type="success" class="help-box">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SaveSucceded</IxRes>
      </HelpBox>
      <hr v-if="!isFromDetailsView">
      <div>
        <h4>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.PlanExported</IxRes>
        </h4>
        <p>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.ExportInfo1</IxRes>
          (<strong>SHAPE</strong>)
          <IxRes>Areas.LanePlanning.SR_LanePlanning.ExportInfo2</IxRes>
        </p>
      </div>
      <VueSelectize
        v-model="format"
        class="selection-system"
        :options="targetSystems"
        :fields="targetSystemFields"
        name="target-selection"
      >
        <template #label>
          <IxRes>Common.SR_Common.Format</IxRes>
        </template>
      </VueSelectize>

      <div class="checkboxes">
        <BsCheckbox v-model="isHeadlandLanesSelected" :disabled="!hasHeadlands">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.HeadlandLaneSettings</IxRes>
        </BsCheckbox>
        <BsCheckbox v-model="isHeadlandSelected" :disabled="isHeadlandDisabled">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.HeadlandSettings</IxRes>
        </BsCheckbox>
        <BsCheckbox v-model="isBorderSelected">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.BorderSettings</IxRes>
        </BsCheckbox>
        <BsCheckbox v-model="includeInfieldLanes">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.InfieldLanesSettings</IxRes>
        </BsCheckbox>
        <BsCheckbox v-model="includeBorderAsCurve">
          <IxRes>Areas.LanePlanning.SR_LanePlanning.PropertiesSettings</IxRes>
        </BsCheckbox>
      </div>

      <div v-if="moreFieldPartsExist" class="container-more-field-parts">
        <hr>
        <div>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.PartialFieldInfo</IxRes>
        </div>
        <div class="checkboxes">
          <BsCheckbox v-model="isOneFileSelected">
            <IxRes>Areas.LanePlanning.SR_LanePlanning.ExportToOneFile</IxRes>
          </BsCheckbox>
        </div>
      </div>
      <hr>
      <IxButton
        large icon="download"
        @click="download"
      >
        <IxRes>Areas.LanePlanning.SR_LanePlanning.PlanExported</IxRes>
      </IxButton>
      <IxButton
        large :icon="icon.faFileExport"
        :disabled="!declassifiedOsbFormat"
        @click="showOsbModal = true"
      >
        <img width="35px" src="@images/Partner/osb.png">
      </IxButton>
      <LaneSuggestionVectorLayer>
        <HeadlandVectorLayer />
      </LaneSuggestionVectorLayer>
      <DetailViewVectorLayer />
      <AbLineVectorLayer />
      <OsbModal v-if="showOsbModal" v-model="showOsbModal" :lane-plan-ids="selectedLanePlans" />
    </template>

    <template #nextButton>
      <IxButton
        v-if="isFromField" large
        submit
        @click="stopPlanning"
      >
        <IxRes>Areas.LanePlanning.SR_LanePlanning.BackToFieldOverview</IxRes>
      </IxButton>
      <IxButton
        v-else large
        submit
        @click="resetLanePlansSelection"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BackToOrgUnitOverview</IxRes>
      </IxButton>
    </template>
  </WizardNavigationFrame>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxButton from '@components/IxButton'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'

import OsbModal from '@components/data-transfer/OsbModal'
import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import HelpBox from '@components/help/HelpBox'
import WizardNavigationFrame from './widgets/WizardNavigationFrame'
import LaneSuggestionVectorLayer from './widgets/LaneSuggestionVectorLayer'
import HeadlandVectorLayer from './widgets/HeadlandVectorLayer'
import DetailViewVectorLayer from '../manage-lane-plans/widgets/DetailViewVectorLayer'
import AbLineVectorLayer from './widgets/AbLineVectorLayer'

export default {
  components: {
    AbLineVectorLayer,
    DetailViewVectorLayer,
    HeadlandVectorLayer,
    LaneSuggestionVectorLayer,
    HelpBox,
    WizardNavigationFrame,
    BsCheckbox,
    IxButton,
    VueSelectize,
    OsbModal
  },
  mixins: [
    ChangeDetectionMixin
  ],
  data () {
    return {
      selectedFormat: null,
      showOsbModal: false
    }
  },
  computed: {
    icon () {
      return {
        faFileExport: faFileExport
      }
    },
    ...mapFormFields('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'isBorderSelected',
      'includeBorderAsCurve',
      'isHeadlandSelected',
      'isHeadlandLanesSelected',
      'includeInfieldLanes',
      'isOneFileSelected'
    ]),
    ...mapFormFields('fieldRecordSystem/lanePlanning', [
      'ui.selection.format'
    ]),
    ...mapState('fieldRecordSystem/lanePlanning', {
      selectedLanePlansIds: state => state.data.selectedLanePlans,
      isFromDetailsView: state => state.ui.selection.isFromDetailsView,
      targetSystems: state => state.data.targetSystems,
      planId: state => state.ui.selection.planId,
      lanePlansOrgUnit: state => state.data.lanePlansOrgUnit
    }),
    ...mapState('fieldRecordSystem/navigation/location', {
      fieldId: state => state.fieldId,
      orgUnidId: state => state.orgUnidId
    }),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'moreFieldPartsExist'
    ]),
    lanePlans () {
      var lanePlans = this.fieldId
        ? this.lanePlansOrgUnit[this.fieldId]
        : Object.values(this.lanePlansOrgUnit).flat()
      return lanePlans !== undefined ? lanePlans : []
    },
    selectedLanePlans () {
      return this.lanePlans.filter(plan => this.selectedLanePlansIds.includes(plan.id))
    },
    hasHeadlands () {
      return this.selectedLanePlans.filter(plan => plan.partialFields != null)
        .filter(plan => plan.partialFields.filter(partialField =>
          partialField.headland != null).length > 0
        )
        .length > 0
    },
    targetSystemFields () {
      return {
        text: x => x,
        value: x => x
      }
    },
    isFromField () {
      return !!this.fieldId
    },
    declassifiedOsbFormat () {
      return this.format === 'ISOXML'
    },
    isMultiselect () {
      return this.selectedLanePlans.length > 1
    },
    isHeadlandDisabled () {
      return this.isMultiselect ? true : !this.hasHeadlands
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/lanePlanning', [
      'setIsFromDetailsView',
      'setSelectedPlanId'
    ]),
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'stopPlanning',
      'resetLanePlansSelection',
      'download'
    ])
  },
  watch: {
    hasHeadlands: {
      immediate: true,
      handler () {
        this.isHeadlandLanesSelected = this.hasHeadlands
        this.isHeadlandSelected = !this.isHeadlandDisabled
      }
    }
  },
  beforeDestroy () {
    if (this.isFromDetailsView) {
      this.setIsFromDetailsView()
      this.setSelectedPlanId(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkboxes {
  margin-left: 16px;
  margin-top: 24px;
}
.help-box {
  margin: 0;
}
</style>
