<template lang="html">
  <LabelAndMessage required>
    <template #label>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.WorkingWidth</IxRes>:
    </template>
    <div class="machine-settings">
      <HelpBox class="help-box">
        <IxRes>Areas.LanePlanning.SR_LanePlanning.MachineInfo</IxRes>
      </HelpBox>
      <VueSelectize
        v-if="$can('Use.Frs.Actions')"
        v-model="machineId"
        class="machine-selector"
        allow-empty
        :fields="machineFields" :options="machineOptions"
        :placeholder="$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.SelectPlaceholder')"
      >
        <template #label>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.Machine</IxRes>:
        </template>
      </VueSelectize>

      <NumericInput v-model="workingWidth" class="working-width" unit="m">
        <template #label>
          <IxRes>Areas.LanePlanning.SR_LanePlanning.WorkingWidth</IxRes>: *
        </template>
      </NumericInput>
    </div>
  </LabelAndMessage>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'

import PermissionMixin from '@mixins/PermissionMixin'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import HelpBox from '../../../../../../components/help/HelpBox'

export default {
  components: {
    HelpBox,
    LabelAndMessage,
    NumericInput,
    VueSelectize
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'workingWidth',
      'machineId'
    ]),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'availableMachines'
    ]),
    ...mapState('fieldRecordSystem/machines', {
      machines: state => state.data.machines
    }),
    machineOptions () {
      return this.machines.filter(x => x.isArchived === false).map(x => x.id)
    },
    machineFields () {
      return {
        text: x => this.availableMachines[x].name,
        value: x => this.availableMachines[x].id
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning/laneEditBuffer', [
      'setMachineData'
    ])
  },
  watch: {
    machineId (machineId) {
      this.setMachineData(machineId)
    }
  }
}
</script>

<style lang="scss" scoped>
.machine-settings {
  border: 2px solid #cbcbcb;
  padding: 8px;

  .machine-selector {
    width: 80%;
  }
  .working-width {
    width: 35%;
  }
  .help-box {
    margin-bottom: 4px;
  }
}
</style>
