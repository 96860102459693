<template>
  <div>
    <IxVectorLayer
      :features="headlandFeatures"
      :vector-style="styles.headland"
      layer-id="headlands"
    />
    <IxVectorLayer
      :features="headlandLaneFeatures"
      :vector-style="styles.headlandLane"
      layer-id="headlandLanes"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {makeParts, makeHeadlandFeatures, makeHeadlandLaneFeatures} from 'src/js/store/modules/field-record-system/lane-planning/helpers'

import {headland, headlandLane} from '@frs/components/lane-planning/styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      laneEditBuffer: state => state.laneEditBuffer,
      partEditBuffer: state => state.partEditBuffer
    }),
    headlandFeatures () {
      const {selectedPartIndex, fieldParts} = this.laneEditBuffer
      const parts = makeParts(this.partEditBuffer, selectedPartIndex, fieldParts)

      return makeHeadlandFeatures(selectedPartIndex, parts)
    },
    headlandLaneFeatures () {
      const {selectedPartIndex, fieldParts} = this.laneEditBuffer
      const parts = makeParts(this.partEditBuffer, selectedPartIndex, fieldParts)

      return makeHeadlandLaneFeatures(selectedPartIndex, parts)
    },
    styles () {
      return {
        headland: headland,
        headlandLane: headlandLane
      }
    }
  }
}
</script>

<style scoped>

</style>
