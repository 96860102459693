<template>
  <div>
    <IxVectorLayer
      :features="featureAbLines"
      :vector-style="style"
      layer-id="ab-lines"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {abPreview} from '@frs/components/lane-planning/styles'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      fieldParts: state => state.laneEditBuffer.fieldParts
    }),
    featureAbLines () {
      return this.fieldParts.map(part => part.abSegments.map(segment => segment)).reduce((accum, current) => accum.concat(current), [])
    },
    style () {
      return abPreview
    }
  }
}
</script>

<style scoped>
</style>
