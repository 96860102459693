import {unByKey} from 'ol/Observable'
import VectorSource from 'ol/source/Vector'
import Snap from 'ol/interaction/Snap'

import {geoJsonFormat} from '@helpers/openlayers/features'

export default {
  inject: [
    'getMap'
  ],
  props: {
    features: Array
  },
  computed: {
    olFeatures () {
      return this.features.map(f => geoJsonFormat.readFeature(f))
    }
  },
  methods: {
    init () {
      this.source = new VectorSource({features: this.olFeatures})
      this.snapInteraction = new Snap({source: this.source})
      this.map.addInteraction(this.snapInteraction)
    }
  },
  watch: {
    olFeatures (features) {
      this.source.clear()
      this.source.addFeatures(features)
    }
  },
  created () {
    this.listeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
    })
  },
  beforeDestroy () {
    this.map.removeInteraction(this.snapInteraction)

    this.listeners.forEach(key => {
      unByKey(key)
    })
  },
  render () {
    return null
  }
}
