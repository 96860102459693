<template lang="html">
  <div class="lane-planning">
    <PlanningWizard @step="step" />

    <IxVectorLayer
      :features="featurePartialField"
      :vector-style="style"
      layer-id="partialFields"
      auto-focus
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {makePartialFieldFeatures} from 'src/js/store/modules/field-record-system/lane-planning/helpers'
import {partialField} from './styles'

import IxVectorLayer from '@components/map/IxVectorLayer'
import PlanningWizard from './planning-wizard'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  components: {
    IxVectorLayer,
    PlanningWizard
  },
  mixins: [
    DisableDefaultMapInteractionsMixin
  ],
  data () {
    return {
      lineSelectionPlugin: null,
      activeLayerIds: [],
      wizardStep: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      laneEditBuffer: state => state.laneEditBuffer
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'wizardActive'
    ]),
    featurePartialField () {
      const {selectedPartIndex, fieldParts} = this.laneEditBuffer
      return makePartialFieldFeatures(selectedPartIndex, fieldParts)
    },
    style () {
      return partialField
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'startPlanning',
      'stopPlanning'
    ]),
    step (step) {
      this.wizardStep = step
    }
  },
  watch: {
    fieldId () {
      this.stopPlanning()
    }
  }
}
</script>

<style lang="scss">
.lane-planning {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
</style>
