import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Icon from 'ol/style/Icon'
import Circle from 'ol/style/Circle'
import Point from 'ol/geom/Point'
import MultiPoint from 'ol/geom/MultiPoint'

import {labelText, setLabel, modifierStack} from '@helpers/openlayers/styles'
import imgCurve from '@images/contour/kontur-rahmen.png'
import imgLine from '@images/contour/linie-rahmen.png'

export const fields = new Style({
  stroke: new Stroke({width: 2, color: 'rgba(255, 255, 255, 0.5)'}),
  fill: new Fill({color: 'rgba(255, 255, 255, 0.3)'})
})

const getEndpoints = feature => {
  const geometry = feature.getGeometry()
  return [geometry.getFirstCoordinate(), geometry.getLastCoordinate()]
}

const makeSegmentLineStyle = ({color, width}) => new Style({
  stroke: new Stroke({
    color,
    width
  }),
  text: labelText({color})
})

const makeSegmentEndpointStyle = ({color, radius}) => new Style({
  image: new Circle({
    radius,
    fill: new Fill({
      color
    })
  }),
  geometry: feature => new MultiPoint(getEndpoints(feature))
})

const makeSegmentStyle = ({color, width}) => {
  const line = makeSegmentLineStyle({color, width})
  const endpoints = makeSegmentEndpointStyle({color: 'rgb(0,0,0)', radius: width})

  return (feature, resolution) => {
    setSegmentLabel(line, feature, resolution)
    return [line, endpoints]
  }
}

const setSegmentLabel = setLabel(feature => feature.get('globalSegmentIndex').toString())

export const segment = makeSegmentStyle({color: 'rgb(0, 0, 0', width: 3})
export const segmentStyleDuringSplit = makeSegmentStyle({color: 'rgb(255, 0, 0', width: 3})
export const segmentHover = makeSegmentStyle({color: 'rgba(196, 49, 226, 0.7)', width: 3})
export const selectedSegment = makeSegmentStyle({color: 'rgba(255, 102, 153, 1)', width: 5})

export const fieldBorder = new Style({
  stroke: new Stroke({
    color: 'rgb(255,0,0)',
    width: 2
  })
})

export const partialFieldStyle = new Style({
  stroke: new Stroke({
    color: 'rgb(255,0,0)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(204, 102, 0, 1)'
  })
})

export const headland = new Style({
  stroke: new Stroke({
    color: 'rgba(21, 213, 226, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(21, 213, 226, 0.2)'
  })
})

export const laneBase = new Style({
  stroke: new Stroke({
    color: 'rgba(204, 102, 0, 1)',
    width: 2
  })
})

export const laneGapLine = new Style({
  stroke: new Stroke({
    color: 'rgba(204, 102, 0, 0.4)',
    width: 2
  })
})

export const laneSegment = new Style({
  stroke: new Stroke({
    color: 'rgba(224, 142, 0, 1)',
    width: 10
  })
})

export const lane = feature => feature.get('isSegment')
  ? laneSegment
  : feature.get('isGapLine')
    ? laneGapLine
    : laneBase

export const headlandLane = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 69, 0, 1)',
    width: 2
  })
})

const abPreviewBase = new Style({
  stroke: new Stroke({
    color: 'rgba(224, 142, 0, 1)',
    width: 5
  }),
  text: labelText({color: 'rgba(224, 142, 0, 1)'})
})

export const abLine = new Style({
  stroke: new Stroke({
    color: 'rgba(224, 142, 0, 1)',
    width: 3
  })
})

export const border = new Style({
  fill: new Fill({color: 'rgba(255,255,255,0)'}),
  stroke: new Stroke({color: 'rgb(131,136,238)', width: 1})
})

export const selectedABSegmentBase = new Style({
  stroke: new Stroke({
    color: 'rgb(224,100,20)',
    width: 8
  }),
  text: labelText({color: 'rgba(224, 142, 0, 1)'})
})

export const aBSegment = new Style({
  stroke: new Stroke({
    color: 'rgba(21, 213, 226, 1)',
    width: 5
  })
})

export const abPreview = modifierStack(abPreviewBase, [
  setLabel(feature => feature.get('globalSegmentIndex').toString())
])

export const selectedABSegment = modifierStack(selectedABSegmentBase, [
  setLabel(feature => feature.get('globalSegmentIndex').toString())
])

// partial field styles

const selectedIcon = new Style({
  // TODO implement
})

const selectedOutline = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 0, 1)',
    width: 2
  })/*,
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.2)'
  }) */
})

const outline = new Style({
  stroke: new Stroke({
    color: 'rgba(208, 50, 0, 1)',
    width: 2
  }),
  fill: new Fill({
    color: 'rgba(208, 50, 0, 0.2)'
  })
})

const inactiveOutline = new Style({
  stroke: new Stroke({
    color: 'rgba(208, 50, 0, 0.3)',
    width: 2
  })
})

export const partialField = feature => {
  const {selected, finished} = feature.getProperties()

  return selected
    ? [selectedOutline, selectedIcon]
    : finished
      ? [outline]
      : [inactiveOutline]
}

// Todo style ab lines

// returns the length of a line (two points)
// line=[[x0,y0],[x1,y1]]
function lineLength (line) {
  const xd = line[0][0] - line[1][0]
  const yd = line[0][1] - line[1][1]

  return Math.sqrt(xd * xd + yd * yd)
}

// returns the total length of a linestring (multiple points)
// points=[[x0,y0],[x1,y1],[x2,y2],...]
function totalLength (points) {
  let tl = 0
  points.forEach(function (point, i, points) {
    tl += i ? lineLength([points[i - 1], point]) : 0
  })
  return tl
}

// returns a point on a single line (two points) using distance
// line=[[x0,y0],[x1,y1]]
function pointOnLine (line, distance) {
  const t = distance / lineLength(line)
  const xt = (1 - t) * line[0][0] + (t * line[1][0])
  const yt = (1 - t) * line[0][1] + (t * line[1][1])

  return [xt, yt]
}

function getPointByDistance (points, distance) {
  // let totalLength = totalLength(points)
  let cLength = 0
  let oLength
  let result
  points.forEach(function (point, i, points) {
    oLength = cLength
    cLength += i ? lineLength([points[i - 1], point]) : 0
    if (distance <= cLength && distance > oLength) {
      const dd = distance - oLength
      result = pointOnLine([points[i - 1], point], dd)
    }
  })
  return result
}

const getMiddleOfLine = feature => {
  const points = feature.getGeometry().getCoordinates()
  const tl = totalLength(points)
  const middleDistance = tl / 2

  return getPointByDistance(points, middleDistance)
}

const makeSegmentABLineStyle = ({color, width}) => new Style({
  stroke: new Stroke({
    color,
    width
  })
})

const makeSegmentIconStyle = src => new Style({
  image: new Icon({
    src,
    scale: 0.3
  }),
  geometry: feature => feature.getGeometry().getType() === 'LineString'
    ? new Point(getMiddleOfLine(feature))
    : null
})

const makeSegmentABStyle = ({color, width}) => {
  const line = makeSegmentABLineStyle({color, width})
  const curveIcon = makeSegmentIconStyle(imgCurve)
  const lineIcon = makeSegmentIconStyle(imgLine)

  return feature => {
    const curved = feature.get('lineType')

    return [line, curved === 'contour' ? curveIcon : lineIcon]
  }
}

export const segmentAB = makeSegmentABStyle({color: 'rgb(0, 0, 0)', width: 3})
export const segmentABHover = makeSegmentABStyle({color: 'rgb(255, 0, 0)', width: 3})

export const splitCursor = new Style({
  image: new Circle({
    fill: new Fill({
      color: 'rgb(230, 83, 11)'
    }),
    radius: 5
  })
})
