<template lang="html">
  <WizardNavigationFrame @back="generateHeadlands">
    <template #title>
      <IxRes>geotrax.wizard.title</IxRes>
    </template>
    <template #step>
      <IxRes>Areas.LanePlanning.SR_LanePlanning.Step4Title</IxRes>
    </template>

    <template v-if="isPlanningProfileAB">
      <LaneSuggestionsABLines />
      <LaneOffset
        v-model="laneOffset"
        new-line
        @moveLine="moveLine" @newLine="newAbLine"
      />
    </template>
    <template v-else>
      <LaneSuggestions v-model="selectedLane" :lanes="lanes" />
      <LaneOffset v-model="laneOffset" @moveLine="moveLines" />
      <FrsLoadingIndicator :requests="['geotrax.laneSuggestions', 'geotrax.getLanePlansForField']" />
      <LaneSuggestionVectorLayer>
        <HeadlandVectorLayer />
      </LaneSuggestionVectorLayer>
    </template>

    <template #nextButton>
      <IxButton
        :disabled="!canSave"
        large
        save
        icon="save" @click="finish"
      >
        <IxRes>Areas.LanePlanning.SR_LanePlanning.SaveLane</IxRes>
      </IxButton>
    </template>
  </WizardNavigationFrame>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'

import LaneSuggestions from './widgets/LaneSuggestions'
import LaneSuggestionsABLines from './widgets/LaneSuggestionsABLines'
import LaneOffset from './widgets/LaneOffset'

import FrsLoadingIndicator from '../../base/FrsLoadingIndicator'
import WizardNavigationFrame from './widgets/WizardNavigationFrame'
import HeadlandVectorLayer from './widgets/HeadlandVectorLayer'
import LaneSuggestionVectorLayer from './widgets/LaneSuggestionVectorLayer'

export default {
  components: {
    LaneSuggestionVectorLayer,
    HeadlandVectorLayer,
    IxButton,
    WizardNavigationFrame,
    FrsLoadingIndicator,
    LaneSuggestions,
    LaneSuggestionsABLines,
    LaneOffset
  },
  data () {
    return {
      selectedLane: 0, // TODO wire to vuex via computed
      laneOffset: 0 // TODO wire to vuex via computed
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/lanePlanning', {
      lanes: state => state.partEditBuffer.candidateLanes,
      planningProfile: state => state.laneEditBuffer.planningProfile
    }),
    ...mapGetters('fieldRecordSystem/lanePlanning', [
      'isPlanningProfileAB',
      'canSave'
    ]),
    ...mapFormFields('fieldRecordSystem/lanePlanning/partEditBuffer', [
      'isHeadlandGenerated',
      'subStep'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/lanePlanning', [
      'getHeadland',
      'finishPart',
      'moveLines',
      'moveLine',
      'newAbLine'
    ]),
    generateHeadlands () {
      if (!this.isPlanningProfileAB) {
        this.getHeadland()
      } else {
        this.subStep = null
      }
    },
    finish () {
      this.finishPart()
        .then(() => {
          notifications.success(this.$i18n.translate('Messages.State.SR_StateMessages.SaveSuccess'))
        })
        .catch(error => {
          notifications.error(this.$i18n.translate('Messages.State.SR_StateMessages.SaveError'))
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
